import {
  gsap
} from "gsap";
import {
  ScrollTrigger
} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Toastify from 'toastify-js';

window.addEventListener("DOMContentLoaded", () => {
  init()
})

function init() {
  colorMainNav();
  hideSubNav();
  mobileMenu();
  imageReveal();
  submitForm();
  blockScrollAspectRatio();
  blockBigImageReveal();
}

function colorMainNav() {
  const nav = document.querySelector('.c-nav');
  const logo = document.querySelector('.c-nav__logo');

  if (!nav) return;

  const tl = gsap.timeline({
      defaults: {
        ease: 'Power3.easeInOut',
        reversed: true,
        scrollTrigger: {
          trigger: 'body',
          start: "50px top",
          end: 'top',
          scrub: 0.6,
          onEnter: () => nav.classList.add('c-nav--active'),
          onLeave: () => nav.classList.add('c-nav--active'),
          onEnterBack: () => nav.classList.remove('c-nav--active'),
          onLeaveBack: () => nav.classList.remove('c-nav--active'),
        }
      }
    })
    .to(nav, {
      backgroundColor: 'rgba(34, 37, 41, 0.7)',
      backdropFilter: 'blur(15px)'
    });
}

function hideSubNav() {
  const subNav = document.querySelector('.c-nav__sub')

  if (!subNav) return;

  const scrollDown = gsap.timeline({
    reversed: true,
    defaults: {
      ease: 'Power3.easeInOut',
      scrollTrigger: {
        trigger: 'body',
        start: '50px top',
        end: 'top',
        scrub: 0.6
      }
    }
  })

  ScrollTrigger.saveStyles(subNav)

  ScrollTrigger.matchMedia({
    "(min-width: 800px)": function () {
      scrollDown.to(subNav, {
        height: 0,
        opacity: 0,
        margin: 0,
        duration: .6,
        display: 'none'
      })
    }
  })
}

function mobileMenu() {
  const mobileNav = document.querySelector('.c-mobile-nav');
  const wrapper = document.querySelector('.js-wrapper');
  const mobileLang = document.querySelector('.c-nav__lang--mobile');
  const hamburger = document.querySelector('.c-nav__hamburger');
  const mobileItemAnimation = document.querySelectorAll('.c-mobile-nav__item--animation');
  const hamburgerActive = 'c-nav__hamburger--active';

  if (!mobileNav) return;

  const mobileNavReveal = gsap.timeline({
    reversed: true,
    defaults: {
      duration: .6,
      ease: 'Power3.easeInOut'
    }
  })

  const textReveal = gsap.timeline({
    reversed: true,
    defaults: {
      duration: .3,
      y: 0,
      stagger: .1,
      ease: 'Power3.easeInOut'
    }
  })

  ScrollTrigger.saveStyles(mobileNav)

  mobileNavReveal.to(mobileNav, {
      display: 'flex'
    })
    .to(mobileNav, {
      y: 0
    }, 0)
    .to(wrapper, {
      y: window.innerHeight
    }, 0)
    .to('body', {
      overflow: 'hidden'
    }, 0)
    .to(mobileLang, {
      opacity: 1,
      display: 'block'
    }, 0)

  textReveal.to(mobileItemAnimation, {})

  hamburger.addEventListener('click', () => {
    hamburger.classList.contains(hamburgerActive) ? hamburger.classList.remove(hamburgerActive) : hamburger.classList.add(hamburgerActive)
    mobileNavReveal.reversed() ? mobileNavReveal.play() : mobileNavReveal.reverse()
    textReveal.reversed() ? gsap.delayedCall(.4, () => {
      textReveal.play()
    }) : textReveal.reverse(10)
  })

  window.onresize = () => {
    if (window.innerWidth >= 800) {
      if (hamburger.classList.contains(hamburgerActive)) hamburger.classList.remove(hamburgerActive)
      if (!mobileNavReveal.reversed()) mobileNavReveal.reverse()
      if (!textReveal.reversed()) textReveal.reverse()
    }
  }
}

window.googleMaps = function () {
  const locationCenter = {
    lat: 50.874800,
    lng: 2.886020
  }
  const locationLeft = {
    lat: 50.875533,
    lng: 2.905224
  }
  var mobile, desktop;

  const map = new google.maps.Map(document.querySelector('.c-maps'), {
    center: locationLeft,
    zoom: 15,
    mapId: 'fb2c6df8e81cd072',
    disableDefaultUI: true,
  })

  const image = "./assets/static/icons/marker.svg"
  const marker = new google.maps.Marker({
    position: locationCenter,
    map,
    icon: image,
    animation: google.maps.Animation.DROP,
  })

  if (window.innerWidth <= 950) map.setCenter(marker.getPosition())

  window.onresize = () => {
    if (window.innerWidth >= 950) {
      if (typeof (desktop) === "undefined") {
        desktop = true
        mobile = undefined
        map.setCenter(locationLeft)
      }
    } else if (typeof (mobile) === "undefined") {
      mobile = true
      desktop = undefined
      map.setCenter(marker.getPosition())
    }
  }
}

function imageReveal() {
  const revealContainers = document.querySelectorAll(".js-reveal");

  if (!revealContainers) return;

  revealContainers.forEach((container) => {
    const image = container.querySelector(".js-reveal__img");
    const revealPosition = container.getAttribute("data-reveal");
    var containerPosition, imagePosition;

    if (revealPosition == "right") containerPosition = 100, imagePosition = -100, image.style.transformOrigin = revealPosition;
    else if (revealPosition == "left") containerPosition = -100, imagePosition = 100, image.style.transformOrigin = revealPosition;

    const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none none reset",
        }
      })
      .set(container, {
        autoAlpha: 1
      })
      .from(container, 1.25, {
        xPercent: containerPosition,
        ease: 'Power3.easeInOut'
      })
      .from(image, 1.25, {
        xPercent: imagePosition,
        scale: 1.3,
        delay: -1.25,
        ease: 'Power3.easeInOut'
      });
  })
}

function blockScrollAspectRatio() {
  const blockScrollAspectRatio = document.querySelector('.c-block-scroll__aspect-ratio');

  if (!blockScrollAspectRatio) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: blockScrollAspectRatio,
      scrub: 0.8,
      start: 'top 90%',
      end: 'center 10%',
    }
  })

  ScrollTrigger.saveStyles(tl)

  ScrollTrigger.matchMedia({
    "(min-width: 850px)": function () {
      tl.to(blockScrollAspectRatio, {
        margin: 0
      });
    }
  })
}

function blockBigImageReveal() {
  const blockBig = document.querySelector(".js-block-big");

  if (!blockBig) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: blockBig,
      toggleActions: "restart none none reset",
      ease: 'Power3.easeInOut'
    }
  }).from(".js-block-big__content", {
    duration: 2,
    opacity: 0,
    delay: .8
  })
}

// function isIE() {
//     const ua = navigator.userAgent;
//     var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
//     return is_ie;
// }

// if (isIE()) {
//     //alert('It is InternetExplorer');
// } else {
//     //alert('It is NOT InternetExplorer');
// }


function lazyLoad() {
  const lazyElement = document.querySelector('.js-lazy');
  const lazyPreload = document.querySelector('.js-lazy__preload');

  if (!lazyElement) return;

  lazyElement.src = lazyElement.dataset.src
  lazyPreload.classList.add('js-lazy__preload--active');
  lazyElement.classList.remove('js-lazy');
}
window.onload = lazyLoad;

function submitForm() {
  const form = document.querySelector('.c-contact__form');
  const duration = 4500;

  if (!form) return;

  $('.c-contact__form').submit(function (e) {
    e.preventDefault();
    $.post({
      url: '/',
      dataType: 'json',
      data: $(this).serialize(),
      success: function (response) {
        if (response.success) {
          Toastify({
            text: "Je bericht is verstuurd!",
            duration: duration,
            className: 'toastify-success',
          }).showToast();
          form.reset();
        } else {
          Toastify({
            text: "Er is een fout opgetreden, probeer het opnieuw...",
            duration: duration,
            className: 'toastify-error',
          }).showToast();
        }
      }
    });
  });
}